import { Dispatch, SetStateAction, useState } from "react";

interface Props {
  children: (
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
  ) => JSX.Element;
}

export default function CartOpenStateWrapper({ children }: Props) {
  const [open, setOpen] = useState(false);

  return children(open, setOpen);
}
