"use client";
import { sendGTMEvent } from "@next/third-parties/google";
import { useHydrated, useSupabaseSession } from "@utils/hooks";
import React, { useEffect } from "react";

export default function ClarityTracker() {
  const hydrated = useHydrated();
  const session = useSupabaseSession();

  useEffect(() => {
    if (hydrated && session) {
      sendGTMEvent({
        event: "clarity",
        userUid: session.user.app_metadata.iticket_user_uid,
      });
    }
  }, [hydrated, session]);

  return <></>;
}
