"use client";

import { HeartIcon } from "@heroicons/react/20/solid";
import {
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useSelectedLayoutSegments } from "next/navigation";
import Logo from "./iTICKET-logo";
import { COUNTRY_CODE } from "@utils/constants";
import { joinClassNames } from "@utils/helpers";

const YEAR = new Date().getFullYear();
const MONTH = (new Date().getMonth() + 1).toString().padStart(2, "0");
const DATE = new Date().getDate().toString().padStart(2, "0");
const HOURS = new Date().getHours().toString().padStart(2, "0");
const MINUTES = new Date().getMinutes().toString().padStart(2, "0");

export default function Footer() {
  const segments = useSelectedLayoutSegments();

  if (segments.includes("payment")) {
    return <div />;
  }

  return (
    <div id="footer" className="relative z-10 bg-black">
      <div className="mx-auto md:mx-[10vw] min-[960px]:mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-6 lg:py-8">
        <div className="relative grid grid-cols-3">
          <div className="flex flex-col items-center lg:items-start gap-4 col-span-3 lg:col-span-1">
            <div className="flex items-center justify-center w-24 h-24 rounded-full bg-white">
              <Logo size="16" fill="#000000" />
            </div>
            <h3 className="bg-gradient-to-r from-[#4158D0] to-[#FFCC70] via-[#C850C0] bg-clip-text text-transparent w-fit">
              Ticketing. Events. People. Love.
            </h3>
            <div className="hidden lg:flex gap-8">
              <a
                aria-label="iticket-logo"
                href={
                  COUNTRY_CODE === "nz"
                    ? "https://www.facebook.com/iticketnz"
                    : "https://www.facebook.com/iticketau"
                }
                target="_blank"
                className="group"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.5rem"
                  viewBox="0 0 448 512"
                >
                  <path
                    className="fill-gray-500 group-hover:fill-[#1877f2] transition-colors ease-in-out duration-500"
                    d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                  />
                </svg>
              </a>
              <a
                aria-label="instagram-logo"
                href="https://www.instagram.com/iticketnz"
                target="_blank"
                className="group"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.5rem"
                  viewBox="0 0 448 512"
                >
                  <path
                    className="fill-gray-500"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  />
                  <path
                    className="fill-[url(#insta)] opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-500"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  />
                  <defs>
                    <linearGradient
                      id="insta"
                      x1="0%"
                      y1="100%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#f2c800" />
                      <stop offset="30%" stopColor="#f00068" />
                      <stop offset="100%" stopColor="#7333ea" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
              <a
                aria-label="youtube-logo"
                href="https://www.youtube.com/iticketnz"
                target="_blank"
                className="group"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.5rem"
                  viewBox="0 0 576 512"
                >
                  <path
                    className="fill-gray-500 group-hover:fill-[#fe0000] transition-colors ease-in-out duration-500"
                    d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                  />
                </svg>
              </a>
            </div>
            <div className="-mt-2 lg:mt-12">
              <p className="text-white font-medium">
                Made with <HeartIcon className="inline h-5 w-5 text-red-400" />{" "}
                in New Zealand
              </p>
            </div>
            <div className="-mt-2 lg:hidden">
              <div className="text-center text-sm text-white">
                {COUNTRY_CODE === "nz" ? (
                  <p suppressHydrationWarning>
                    © 2004 - {YEAR} iTICKET.CO.NZ Limited.
                  </p>
                ) : (
                  <p suppressHydrationWarning>
                    © 2004 - {YEAR} iTICKET, ABN 49 160 384 403
                  </p>
                )}
              </div>
              <p
                suppressHydrationWarning
                className="text-center text-sm text-gray-500"
              >
                v
                {process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
                  ? process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA.slice(0, 8)
                  : "DEV"}
                .{YEAR}.{MONTH}
                {DATE}.{HOURS}
                {MINUTES}
              </p>
            </div>
          </div>
          <div className="hidden lg:grid mt-9 col-span-2 grid-cols-4">
            <div
              className={joinClassNames(
                "flex flex-col gap-4",
                COUNTRY_CODE === "nz" ? "col-start-2" : "col-start-3"
              )}
            >
              <h4 className="text-white font-bold">Support</h4>
              <Link
                href="/contact"
                className="group flex items-center gap-1 text-white w-fit"
              >
                Contact
                <ArrowRightIcon className="h-4 w-4 text-white -translate-x-2 opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transition-all ease-in-out duration-200" />
              </Link>
              <Link
                href="/frequently-asked-questions"
                className="group flex items-center gap-1 text-white w-fit"
              >
                FAQs
                <ArrowRightIcon className="h-4 w-4 text-white -translate-x-2 opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transition-all ease-in-out duration-200" />
              </Link>
              <Link
                href="/account"
                className="group flex items-center gap-1 text-white w-fit"
              >
                My Account
                <ArrowRightIcon className="h-4 w-4 text-white -translate-x-2 opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transition-all ease-in-out duration-200" />
              </Link>
            </div>
            <div className="flex flex-col gap-4">
              <h4 className="text-white font-bold">Legal</h4>
              <Link
                prefetch={false}
                data-testid="terms"
                href="/legal/terms"
                className="group flex items-center gap-1 text-white w-fit"
              >
                Terms and Conditions
                <ArrowRightIcon className="h-4 w-4 text-white -translate-x-2 opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transition-all ease-in-out duration-200" />
              </Link>
              <Link
                prefetch={false}
                data-testid="privacy"
                href="/legal/privacy"
                className="group flex items-center gap-1 text-white w-fit"
              >
                Privacy
                <ArrowRightIcon className="h-4 w-4 text-white -translate-x-2 opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transition-all ease-in-out duration-200" />
              </Link>
            </div>
            {COUNTRY_CODE === "nz" ? (
              <div className="flex flex-col gap-4">
                <h4 className="text-white font-bold">Links</h4>
                <a
                  href="https://blog.iticket.co.nz/"
                  target="_blank"
                  className="group flex items-center gap-1 text-white w-fit"
                >
                  Blog
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 text-white -translate-x-2 opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transition-all ease-in-out duration-200" />
                </a>
                <a
                  href="https://boxoffice.iticket.co.nz/"
                  target="_blank"
                  className="group flex items-center gap-1 text-white w-fit"
                >
                  Promoter Boxoffice
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 text-white -translate-x-2 opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transition-all ease-in-out duration-200" />
                </a>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="hidden lg:block absolute bottom-0 right-0 text-right">
            <p suppressHydrationWarning className="text-sm text-gray-500">
              {`v${
                process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
                  ? process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA.slice(0, 8)
                  : "DEV"
              }.${YEAR}.${MONTH}${DATE}.${HOURS}${MINUTES}`}
            </p>
            {COUNTRY_CODE === "nz" ? (
              <p suppressHydrationWarning className="text-sm text-white">
                © 2004 - {YEAR} iTICKET.CO.NZ Limited.
              </p>
            ) : (
              <p suppressHydrationWarning className="text-sm text-white">
                © 2004 - {YEAR} iTICKET, ABN 49 160 384 403
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
