import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/app/globals.css");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/components/analytics.ts");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/components/clarity/clarityTracker.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/context/RoktLauncherContext.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/features/cookiebanner/cookieBanner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/features/Footer.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/features/Navbar/Navbar.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/iticket-shopsite/iticket-shopsite/src/features/notification/notification.tsx")