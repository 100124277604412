"use client";

import { XMarkIcon } from "@heroicons/react/24/solid";
import * as Toast from "@radix-ui/react-toast";
import { useNotificationStore } from "@store/notificationStore";
import { BasketSummary } from "@types";
import { basketSummaryFetcher } from "@utils/fetchers";
import { joinClassNames } from "@utils/helpers";
import { useLatestCookieValue, useWindowSize } from "@utils/hooks";
import Link from "next/link";
import { usePathname } from "next/navigation";
import useSWR from "swr";

const Notification = () => {
  const pathname = usePathname();
  const basketKey = useLatestCookieValue("ASP.NET_SessionId");
  const windowSize = useWindowSize();
  const showNotification = useNotificationStore((state) => state.show);
  const setShowNotification = useNotificationStore((state) => state.setShow);
  const content = useNotificationStore((state) => state.content);
  const { data: basket } = useSWR<BasketSummary>(
    basketKey ? `basket/${basketKey}/summary` : null,
    basketSummaryFetcher,
    {
      refreshInterval: 30 * 1000,
      refreshWhenHidden: true,
    }
  );

  const colorVariants: { [key: string]: string } = {
    emerald: "text-emerald-400",
    red: "text-red-400",
    orange: "text-orange-400",
  };

  if (
    !pathname.includes("cart") &&
    basket &&
    basket.Quantity > 0 &&
    windowSize.width &&
    windowSize.width <= 640
  ) {
    return (
      <Toast.Provider swipeDirection="down">
        <Toast.Root
          open={showNotification}
          onOpenChange={setShowNotification}
          className={joinClassNames(
            "flex w-full p-4 rounded-lg shadow-lg pointer-events-auto bg-white backdrop-blur-popover ring-1 ring-black ring-opacity-5 overflow-hidden",
            "data-[swipe=move]:transition-none transition-all data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[swipe=cancel]:translate-y-0 data-[swipe=end]:translate-y-[var(--radix-toast-swipe-end-y)] data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:slide-out-to-bottom-full data-[state=open]:slide-in-from-bottom-full"
          )}
          duration={Infinity}
        >
          <Toast.Description className="flex flex-col w-full">
            <div className="flex items-center justify-between">
              <div className="flex">
                {content.icon ? (
                  <content.icon.icon
                    className={joinClassNames(
                      `shrink-0 h-6 w-6 ${colorVariants[content.icon.color]}`
                    )}
                  />
                ) : null}
                <div className="ml-3 pt-0.5">
                  <p className="text-sm font-medium text-black">
                    {content?.content}
                  </p>
                </div>
              </div>
              <Toast.Close className="inline-flex text-gray-400 rounded-md h-min hover:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white focus-visible:ring-indigo-500">
                <span className="sr-only">Close</span>
                <XMarkIcon className="w-5 h-5" aria-hidden="true" />
              </Toast.Close>
            </div>
            <div className="flex flex-col mt-6 gap-4">
              <Link
                href="/cart"
                onClick={() => setShowNotification(false)}
                className="flex items-center justify-center w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 text-white font-semibold transition-colors duration-200 ease-in-out"
              >
                Checkout
              </Link>
              <button onClick={() => setShowNotification(false)}>
                Continue shopping
              </button>
            </div>
          </Toast.Description>
        </Toast.Root>
        <Toast.Viewport className="fixed bottom-4 w-full sm:top-[5.5rem] sm:right-0 flex flex-col sm:p-6 gap-3 sm:w-96 sm:max-w-[100vw] px-4 sm:m-0 z-[1000] outline-none" />
      </Toast.Provider>
    );
  }

  return (
    <Toast.Provider>
      <Toast.Root
        open={showNotification}
        onOpenChange={setShowNotification}
        className={joinClassNames(
          "flex w-full p-4 rounded-lg shadow-lg pointer-events-auto bg-white backdrop-blur-popover ring-1 ring-black ring-opacity-5 overflow-hidden",
          "data-[swipe=move]:transition-none transition-all data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=open]:max-sm:slide-in-from-top-full data-[state=open]:sm:slide-in-from-right-full data-[state=closed]:sm:slide-out-to-right-full"
        )}
        duration={
          pathname.includes("events") && basket && basket.Quantity > 0
            ? Infinity
            : 3000
        }
      >
        <Toast.Description className="flex flex-col w-full">
          <div className="flex">
            {content.icon ? (
              <content.icon.icon
                className={joinClassNames(
                  `shrink-0 h-6 w-6 ${colorVariants[content.icon.color]}`
                )}
              />
            ) : null}
            <div className="ml-3 pt-0.5">
              <p className="text-sm font-medium text-black">
                {content?.content}
              </p>
            </div>
          </div>
          {!pathname.includes("cart") && basket && basket.Quantity > 0 ? (
            <div className="flex flex-col mt-6 gap-4">
              <Link
                href="/cart"
                onClick={() => setShowNotification(false)}
                className="flex items-center justify-center w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 text-white font-semibold transition-colors duration-200 ease-in-out"
              >
                Checkout
              </Link>
              <button onClick={() => setShowNotification(false)}>
                Continue shopping
              </button>
            </div>
          ) : (
            <></>
          )}
        </Toast.Description>
        <Toast.Close className="inline-flex text-gray-400 rounded-md h-min hover:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white focus-visible:ring-indigo-500">
          <span className="sr-only">Close</span>
          <XMarkIcon className="w-5 h-5" aria-hidden="true" />
        </Toast.Close>
      </Toast.Root>
      <Toast.Viewport className="fixed top-20 w-full sm:top-[5.5rem] sm:right-0 flex flex-col p-6 gap-3 sm:w-96 sm:max-w-[100vw] m-0 z-[1000] outline-none" />
    </Toast.Provider>
  );
};

export default Notification;
