"use client";

import { IntegrationLauncher } from "@types";
import React, { createContext, useContext, useEffect, useState } from "react";

export const RoktLauncherContext = createContext<IntegrationLauncher | null>(
  null
);

export function useRoktLauncher() {
  return useContext(RoktLauncherContext);
}

export function RoktLauncherContextProvider({
  children,
  accountId,
  sandbox,
}: {
  children: React.ReactNode;
  accountId: string;
  sandbox: boolean;
}) {
  const [launcher, setLauncher] = useState<IntegrationLauncher | null>(null);

  useEffect(() => {
    (async () => {
      // Guards against Rokt script being still loaded into the application when the context is created
      // Now you can use the 'Rokt' type in your code
      await new Promise<void>((resolve) => {
        const roktLauncher = document.getElementById(
          "rokt-launcher"
        ) as HTMLScriptElement;

        if (window.Rokt) {
          resolve();
        } else {
          // Explicitly type the onload event handler
          roktLauncher.onload = (event: Event) => resolve();
        }
      });

      const launcherInstance = await window.Rokt.createLauncher({
        accountId: accountId,
        sandbox: sandbox,
      });

      setLauncher(launcherInstance);
    })();

    return () => {
      if (launcher) {
        launcher.terminate();
      }
    };
  }, [accountId, sandbox]);

  // Return the context provider
  return (
    <RoktLauncherContext.Provider value={launcher}>
      {children}
    </RoktLauncherContext.Provider>
  );
}
