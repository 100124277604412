"use client";

import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";

const write_key = process.env.NEXT_PUBLIC_CIO_PIPELINE_KEY as string;

export const analytics = AnalyticsBrowser.load({
  writeKey: write_key,
});

export default function CustomerIOAnalytics() {
  return null;
}
