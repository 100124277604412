"use client";

import * as Toast from "@radix-ui/react-toast";
import { joinClassNames } from "@utils/helpers";
import { useLatestCookieValue } from "@utils/hooks";
import axios from "axios";
import Link from "next/link";
import { useState } from "react";

const CookieBanner = () => {
  const cookieConsent = useLatestCookieValue("itk_cookieconsent");
  const [open, setOpen] = useState(cookieConsent !== "dismissed");

  return (
    <Toast.Provider>
      <Toast.Root
        open={open}
        className={joinClassNames(
          "flex w-full p-4 rounded-lg shadow-lg pointer-events-auto bg-white backdrop-blur-popover ring-1 ring-black ring-opacity-5 overflow-hidden",
          "transition-all data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=open]:max-sm:slide-in-from-top-full data-[state=open]:sm:slide-in-from-right-full data-[state=closed]:sm:slide-out-to-right-full"
        )}
      >
        <Toast.Description className="flex flex-col w-full">
          <div className="flex">
            <div className="pt-0.5">
              <h2 className="font-medium text-black">Cookie Consent</h2>
              <p className="text-black leading-6 pt-2">
                This website uses cookies to ensure you get the best experience
                on our website.{" "}
                <Link
                  href={"/legal/privacy"}
                  className="underline text-gray-800"
                >
                  Learn more
                </Link>
              </p>
            </div>
          </div>
          <div className="flex flex-col mt-6 gap-4">
            <Toast.Close
              onClick={() => {
                setOpen(false);
                axios.post("/api/cookieconsent");
              }}
              className="flex items-center justify-center w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 text-white font-semibold transition-colors  duration-200 ease-in-out"
            >
              Got It
            </Toast.Close>
          </div>
        </Toast.Description>
      </Toast.Root>
      <Toast.Viewport className="fixed top-20 w-full sm:top-[5.5rem] sm:right-0 flex flex-col p-6 gap-3 sm:w-96 sm:max-w-[100vw] m-0 z-[1000] outline-none" />
    </Toast.Provider>
  );
};

export default CookieBanner;
