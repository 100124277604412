import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { COUNTRY_CODE } from "@utils/constants";
import { joinClassNames } from "@utils/helpers";
import { usePrevious } from "@utils/hooks";
import { motion } from "framer-motion";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState } from "react";

const mockGenres =
  COUNTRY_CODE === "nz"
    ? [
        { name: "Music", href: "/whats-on/music" },
        { name: "Theatre & Arts", href: "/whats-on/theatre-arts" },
        {
          name: "Festivals & Lifestyle",
          href: "/whats-on/festivals-lifestyle",
        },
        { name: "Experiences", href: "/whats-on/experiences" },
        { name: "Sport", href: "/whats-on/sport" },
        { name: "Nightlife", href: "/whats-on/nightlife" },
      ]
    : [{ name: "Experiences", href: "/whats-on/experiences" }];

/**
 * A navigation menu for genres. This is using radix react navigation menu UI alongside
 * framer motion to create the smooth background animation when hovering. Documentation for react nav menu https://www.radix-ui.com/docs/primitives/components/navigation-menu
 *
 */
export default function GenreNavMenu() {
  const pathname = usePathname();
  const [hoveredGenre, setHoveredGenre] = useState<{
    width: number;
    x: number;
  } | null>(null);
  const lastHovered = usePrevious(hoveredGenre);

  return (
    <NavigationMenu.Root>
      <NavigationMenu.List className="relative flex items-center">
        {mockGenres.map((genre, index) => (
          <NavigationMenu.Item key={genre.name}>
            <Link
              data-testid={genre.name}
              id={genre.name}
              className={joinClassNames(
                "relative block z-10 px-4 py-2 font-medium hover:text-indigo-400 transition-colors ease-in-out duration-200",
                pathname === genre.href ? "text-indigo-400" : "text-gray-400"
              )}
              onMouseEnter={() => {
                const width =
                  document.getElementById(genre.name)?.getBoundingClientRect()
                    .width ?? 0;
                let x = 0;
                for (let i = 0; i < index; ++i) {
                  x +=
                    document
                      .getElementById(mockGenres[i].name)
                      ?.getBoundingClientRect().width ?? 0;
                }
                setHoveredGenre({ width, x });
              }}
              onMouseLeave={() => setHoveredGenre(null)}
              href={genre.href}
            >
              {genre.name}
            </Link>
          </NavigationMenu.Item>
        ))}
        <motion.li
          animate={
            hoveredGenre
              ? { width: hoveredGenre.width, x: hoveredGenre.x, opacity: 1 }
              : {
                  width: lastHovered?.width,
                  x: lastHovered?.x,
                  opacity: 0,
                  transition: { opacity: { duration: 0.5 } },
                }
          }
          transition={{ ease: "easeInOut", duration: 0.2 }}
          className="absolute h-10 rounded-lg top-0 bg-indigo-950 pointer-events-none"
        />
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}
