"use client";

import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  ArrowTopRightOnSquareIcon,
  ClockIcon,
  IdentificationIcon,
  InboxStackIcon,
  TicketIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";
import * as Dialog from "@radix-ui/react-dialog";
import { createBrowserClient } from "@supabase/ssr";
import {
  COUNTRY_CODE,
  SUPABASE_ANON_KEY,
  SUPABASE_URL,
} from "@utils/constants";
import { authorizedFetcher } from "@utils/fetchers";
import { joinClassNames } from "@utils/helpers";
import { useSupabaseSession } from "@utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import useSWR from "swr";

const mockGenres =
  COUNTRY_CODE === "nz"
    ? [
        { name: "Music", href: "/whats-on/music" },
        { name: "Theatre & Arts", href: "/whats-on/theatre-arts" },
        {
          name: "Festivals & Lifestyle",
          href: "/whats-on/festivals-lifestyle",
        },
        { name: "Experiences", href: "/whats-on/experiences" },
        { name: "Sport", href: "/whats-on/sport" },
        { name: "Nightlife", href: "/whats-on/nightlife" },
      ]
    : [{ name: "Experiences", href: "/whats-on/experiences" }];

export default function MobileMenu() {
  const router = useRouter();
  const supabase = createBrowserClient(SUPABASE_URL!, SUPABASE_ANON_KEY!);
  const session = useSupabaseSession();
  const [open, setOpen] = useState(false);
  const pathname = usePathname();
  const { data: account } = useSWR(
    session && session.access_token ? ["account", session?.access_token] : null,
    authorizedFetcher
  );

  return (
    <div className="relative h-6">
      <Dialog.Root modal={false} open={open} onOpenChange={setOpen}>
        <Dialog.Trigger
          aria-controls="radix-:R335j9:"
          className="absolute top-0 right-0 text-gray-600 rounded focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset transition-colors ease-in-out duration-200 focus-visible:ring-indigo-500"
        >
          <span className="sr-only">Open main menu</span>
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#ffffff"
            strokeWidth="2"
          >
            <motion.line
              x1={4}
              x2={20}
              y1={6}
              y2={6}
              animate={
                open
                  ? { rotate: 45, translateY: 6 }
                  : { rotate: 0, translateY: 0 }
              }
              strokeLinecap="round"
              strokeLinejoin="round"
              transition={{ type: "tween", duration: 0.2 }}
            />
            <motion.line
              x1={4}
              x2={20}
              y1={12}
              y2={12}
              animate={open ? { opacity: 0 } : { opacity: 1 }}
              strokeLinecap="round"
              strokeLinejoin="round"
              transition={{ type: "tween", duration: 0.15 }}
            />
            <motion.line
              x1={4}
              x2={20}
              y1={18}
              y2={18}
              animate={
                open
                  ? { rotate: -45, translateY: -6 }
                  : { rotate: 0, translateY: 0 }
              }
              strokeLinecap="round"
              strokeLinejoin="round"
              transition={{ type: "tween", duration: 0.2 }}
            />
          </motion.svg>
        </Dialog.Trigger>
        <AnimatePresence>
          {open && (
            <Dialog.Portal forceMount>
              <Dialog.Content
                asChild
                forceMount
                className="fixed inset-0 z-[49] w-screen"
              >
                <motion.div
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={{
                    hidden: { height: 0, transition: { duration: 0.125 } },
                    show: { height: "auto" },
                  }}
                  transition={{ duration: 0.25 }}
                >
                  <nav
                    className="h-full bg-black pt-[5.5rem]"
                    aria-label="Global"
                  >
                    <div className="box-border flex flex-col h-full">
                      <div className="overflow-y-auto grow">
                        <div className="px-2 pb-4 mx-auto max-w-8xl">
                          {account ? (
                            <h2 className="flex items-center gap-1 p-1.5 text-3xl font-black text-white">
                              <motion.div
                                initial={{ rotate: 0 }}
                                animate={{
                                  rotate: [
                                    0, 30, 30, 30, 60, 0, 60, 0, 60, 30, 30, 30,
                                    0,
                                  ],
                                }}
                                transition={{ duration: 1.5, delay: 0.5 }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 256 256"
                                  fill="#facc15"
                                  className="h-8 w-8"
                                >
                                  <path
                                    d="M213.27,104l-18-31.18a20,20,0,0,0-34.64,20l-28-48.5A20,20,0,0,0,98,64.31L90,50.46a20,20,0,0,0-34.64,20l12,20.83-1.17,1A20,20,0,0,0,36.7,118.17L74.7,184a80,80,0,0,0,138.57-80Zm-57.59,60.64.17-.1.14.24Z"
                                    opacity="0.2"
                                  />
                                  <path d="M220.2,100l-18-31.18a28,28,0,0,0-47.3-1.92L139.56,40.31a28,28,0,0,0-48.12-.63,28,28,0,0,0-43,34.78l3.34,5.79a28,28,0,0,0-22,41.92l38,65.82a87.46,87.46,0,0,0,53.43,41,88.56,88.56,0,0,0,22.92,3A88,88,0,0,0,220.2,100Zm-6.67,62.63A72,72,0,0,1,81.63,180l-38-65.82a12,12,0,0,1,20.79-12l22,38.1a8,8,0,1,0,13.85-8l-38-65.81a12,12,0,0,1,13.5-17.59,11.9,11.9,0,0,1,7.29,5.59l34,58.89a8,8,0,0,0,13.85-8l-26-45h0a12,12,0,0,1,20.78-12L160,107.78a48.08,48.08,0,0,0-11,61,8,8,0,0,0,13.86-8,32,32,0,0,1,11.71-43.71,8,8,0,0,0,2.93-10.93l-10-17.32a12,12,0,0,1,20.78-12l18,31.18A71.49,71.49,0,0,1,213.53,162.62ZM184.27,29.93a8,8,0,0,1,9.8-5.66c15.91,4.27,29,14.11,36.86,27.73a8,8,0,0,1-13.86,8c-5.72-9.92-15.36-17.12-27.14-20.27A8,8,0,0,1,184.27,29.93ZM80.91,237a8,8,0,0,1-11.24,1.33c-11-8.69-20.11-19.58-28.6-34.28a8,8,0,0,1,13.86-8c7.44,12.88,15.27,22.32,24.65,29.72A8,8,0,0,1,80.91,237Z" />
                                </svg>
                              </motion.div>
                              Hi {account?.firstname}
                            </h2>
                          ) : (
                            <></>
                          )}
                          {session ? (
                            <>
                              <Link
                                href="/account"
                                onClick={() => setOpen(false)}
                                className={joinClassNames(
                                  "flex gap-3 text-lg items-center px-3 py-2 font-medium rounded-xl",
                                  pathname === "/account"
                                    ? "text-gray-300 bg-white/10"
                                    : "text-gray-400"
                                )}
                              >
                                <UserIcon className="h-6 w-6" />
                                <span>My Account</span>
                              </Link>
                              <Link
                                href="/account/my-tickets"
                                onClick={() => setOpen(false)}
                                className={joinClassNames(
                                  "flex gap-3 text-lg items-center px-3 py-2 font-medium rounded-xl",
                                  pathname === "/account/my-tickets"
                                    ? "text-gray-300 bg-white/10"
                                    : "text-gray-400"
                                )}
                              >
                                <TicketIcon className="h-6 w-6" />
                                <div className="flex items-center gap-2">
                                  <span>My Tickets</span>
                                  <span className="inline-flex items-center gap-1 rounded-lg px-1 py-0.5 text-sm font-semibold bg-indigo-500 max-h-6">
                                    <SparklesIcon className="w-4 h-4 text-yellow-400 animate-shimmer" />
                                    <span className="text-indigo-50">
                                      {"New".split("").map((char, index) => (
                                        <span
                                          key={index}
                                          className="animate-shimmer"
                                          style={{
                                            animationDelay: `${
                                              50 * (index + 1)
                                            }ms`,
                                          }}
                                        >
                                          {char}
                                        </span>
                                      ))}
                                    </span>
                                  </span>
                                </div>
                              </Link>
                              <Link
                                href="/account/order-history"
                                onClick={() => setOpen(false)}
                                className={joinClassNames(
                                  "flex gap-3 text-lg items-center px-3 py-2 font-medium rounded-xl",
                                  pathname === "/account/order-history"
                                    ? "text-gray-300 bg-white/10"
                                    : "text-gray-400"
                                )}
                              >
                                <ClockIcon className="h-6 w-6" />
                                <span>Order History</span>
                              </Link>
                              <Link
                                href="/account/e-pass"
                                onClick={() => setOpen(false)}
                                className={joinClassNames(
                                  "flex gap-3 text-lg items-center px-3 py-2 font-medium rounded-xl",
                                  pathname === "/account/e-pass"
                                    ? "text-gray-300 bg-white/10"
                                    : "text-gray-400",
                                  COUNTRY_CODE === "au" ? "hidden" : ""
                                )}
                              >
                                <IdentificationIcon className="h-6 w-6" />
                                <span>ePass</span>
                              </Link>
                              <Link
                                href="/account/subscription-preferences/updates"
                                onClick={() => setOpen(false)}
                                className={joinClassNames(
                                  "flex gap-3 text-lg items-center px-3 py-2 font-medium rounded-xl",
                                  pathname.startsWith(
                                    "/account/subscription-preferences/"
                                  )
                                    ? "text-gray-300 bg-white/10"
                                    : "text-gray-400",
                                  COUNTRY_CODE === "au" ? "hidden" : ""
                                )}
                              >
                                <InboxStackIcon className="h-6 w-6" />
                                <span>Subscription Preferences</span>
                              </Link>
                              <button
                                onClick={async () => {
                                  await supabase.auth.signOut();
                                  setOpen(false);
                                  router.refresh();
                                }}
                                className="flex gap-3 text-lg items-center px-3 py-2 font-medium text-gray-400 focus:outline-none"
                              >
                                <ArrowRightOnRectangleIcon className="h-6 w-6" />
                                <span>Sign out</span>
                              </button>
                            </>
                          ) : (
                            <>
                              <h2 className="px-1.5 text-2xl text-white font-extrabold">
                                Account
                              </h2>
                              <Link
                                href="/account/sign-in"
                                onClick={() => setOpen(false)}
                                className={joinClassNames(
                                  "flex gap-3 text-lg items-center px-3 py-2 font-medium rounded-xl",
                                  pathname === "/account/sign-in"
                                    ? "text-gray-300 bg-white/10"
                                    : "text-gray-400"
                                )}
                              >
                                <ArrowLeftOnRectangleIcon className="h-6 w-6" />
                                <span>Sign in</span>
                              </Link>
                            </>
                          )}
                          <h2 className="mt-2 px-1.5 text-2xl text-white font-extrabold">
                            Genres
                          </h2>
                          {mockGenres.map((genre) => (
                            <Link
                              key={genre.name}
                              href={genre.href}
                              onClick={() => setOpen(false)}
                              className={joinClassNames(
                                "block text-lg px-3 py-2 font-medium rounded-xl",
                                pathname === genre.href
                                  ? "text-gray-300 bg-white/10"
                                  : "text-gray-400"
                              )}
                            >
                              {genre.name}
                            </Link>
                          ))}
                          <h2 className="mt-2 px-1.5 text-2xl text-white font-extrabold">
                            Support
                          </h2>
                          <Link
                            href="/contact"
                            onClick={() => setOpen(false)}
                            className={joinClassNames(
                              "block text-lg px-3 py-2 font-medium rounded-xl",
                              pathname === "/contact"
                                ? "text-gray-300 bg-white/10"
                                : "text-gray-400"
                            )}
                          >
                            Contact
                          </Link>
                          <Link
                            href="/frequently-asked-questions"
                            onClick={() => setOpen(false)}
                            className={joinClassNames(
                              "block text-lg px-3 py-2 font-medium rounded-xl",
                              pathname === "/frequently-asked-questions"
                                ? "text-gray-300 bg-white/10"
                                : "text-gray-400"
                            )}
                          >
                            FAQs
                          </Link>
                          <h2 className="mt-2 px-1.5 text-2xl text-white font-extrabold">
                            Legal
                          </h2>
                          <Link
                            prefetch={false}
                            href="/legal/terms"
                            onClick={() => setOpen(false)}
                            className={joinClassNames(
                              "block text-lg px-3 py-2 font-medium rounded-xl",
                              pathname === "/legal/terms"
                                ? "text-gray-300 bg-white/10"
                                : "text-gray-400"
                            )}
                          >
                            Terms and Conditions
                          </Link>
                          <Link
                            prefetch={false}
                            href="/legal/privacy"
                            onClick={() => setOpen(false)}
                            className={joinClassNames(
                              "block text-lg px-3 py-2 font-medium rounded-xl",
                              pathname === "/legal/privacy"
                                ? "text-gray-300 bg-white/10"
                                : "text-gray-400"
                            )}
                          >
                            Privacy
                          </Link>
                          {COUNTRY_CODE === "nz" && (
                            <>
                              <h2 className="mt-2 px-1.5 text-2xl text-white font-extrabold">
                                Links
                              </h2>
                              <a
                                href="https://blog.iticket.co.nz/"
                                target="_blank"
                                onClick={() => setOpen(false)}
                                className="flex items-center gap-2 text-lg px-3 py-2 font-medium text-gray-400"
                              >
                                Blog
                                <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-2" />
                              </a>
                              <a
                                href="https://boxoffice.iticket.co.nz/"
                                target="_blank"
                                onClick={() => setOpen(false)}
                                className="flex items-center gap-2 text-lg px-3 py-2 font-medium text-gray-400"
                              >
                                Promoter Boxoffice
                                <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-2" />
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </nav>
                </motion.div>
              </Dialog.Content>
            </Dialog.Portal>
          )}
        </AnimatePresence>
      </Dialog.Root>
    </div>
  );
}
