import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { Icon } from "@types";
import { create } from "zustand";

interface Content {
  id?: string;
  icon?: {
    icon: Icon;
    color: string;
  };
  content?: string | JSX.Element;
}

interface NotificationState {
  show: boolean;
  setShow: (show: boolean) => void;
  content: Content;
  setContent: (content: Content) => void;
  setSuccess: (message?: string) => void;
  setFailure: (message?: string, is500?: boolean) => void;
}

export const useNotificationStore = create<NotificationState>()((set) => ({
  show: false,
  setShow: (show) => set(() => ({ show })),
  content: {},
  setContent: (content) => set(() => ({ content })),
  setSuccess: (message = "Successfully saved!") =>
    set(() => ({
      content: {
        icon: { icon: CheckCircleIcon, color: "emerald" },
        content: message,
      },
      show: true,
    })),
  setFailure: (message = "Failed to save!", is500 = false) =>
    set(() => ({
      content: {
        icon: {
          icon: is500 ? XCircleIcon : ExclamationCircleIcon,
          color: is500 ? "red" : "orange",
        },
        content: message,
      },
      show: true,
    })),
}));
